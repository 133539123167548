.admin-email-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1vh;
}

.admin-title-container {
  display: flex;
  flex-direction: column;
  gap: 1vh;
}

.admin-content-container {
  display: flex;
  flex-direction: column;
  gap: 1vh;
}

.long-large-input {
  width: 10vw;
  height: 5vh;
  border-radius: 10px;
}

.large-input {
  width: 10vw;
  border-radius: 10px;
}

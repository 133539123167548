.select-area-input {
  display: inline-flex;
  height: 88%;
  width: 50px;
  background-color: white;
  border-bottom-right-radius: 10%;
  border-top-right-radius: 10%;
  border-color: cornflowerblue;
  position: relative;
  z-index: 1;
  font-family: "Roboto Medium";
  font-size: 13px;
  text-align-last: center;
}

.select-area-input-invalid {
  border-color: red;
}

.select-area-label {
  display: inline-flex;
  height: 100%;
  width: 50px;
  justify-content: center;
  align-items: center;
  background-color: cornflowerblue;
  border-bottom-left-radius: 10%;
  border-top-left-radius: 10%;
  position: relative;
  z-index: 1;
  font-family: "Roboto Medium";
  font-size: 13px;
  text-align: center;
  padding-right: 2px;
}

.select-area-label-invalid {
  background-color: red;
}

.select-area-input-invalid:focus {
  outline: none !important;
  border: 1px solid red;
  box-shadow: 0 0 10px red;
}

.select-area-container {
  display: inline-flex;
  height: 50px;
}

.select-area-input::-webkit-outer-spin-button,
.select-area-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.select-area-input[type="number"] {
  -moz-appearance: textfield;
}

.loading-button {
  font-size: 0.8em;
  font-family: "Roboto Regular";
  background: cornflowerblue;
  padding: 1em 2em;
  cursor: pointer;
  overflow: hidden;
  border-radius: 10px;
  user-select: none;
  text-decoration: none;
}

.loading-button:hover {
  background-color: lightskyblue;
}

.loading-button:disabled {
  background-color: lightgrey;
  color: grey;
  cursor: auto;
}
.spinner {
  animation-name: spin;
  animation-duration: 500ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.admin-version-list-container {
  display: flex;
  flex-direction: column;
}

.admin-version-list-content {
  width: 100%;
}

.ul-filename {
  list-style-type: none;
}

.admin-version-list-version-list-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.admin-version-list-version-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 1vw;
  max-width: 30%;
}

.admin-version-list-version-value {
  width: 100%;
}

.admin-version-list-warning-information {
  font-size: 28px;
  font-weight: normal;
  width: min-content;
  background: none;
  border: none;
  cursor: pointer;
  margin: 2%;
  text-decoration: none;
  color: red;
}

.admin-version-list-warning-information:hover {
  font-weight: bold;
}

.admin-version-list-delete-button {
  font-size: 28px;
  font-weight: bold;
  width: min-content;
  background: none;
  border: none;
  cursor: pointer;
  margin: 2%;
  text-decoration: none;
  color: red;
}

.outer-popup {
  position: absolute;
  width: 100vw;
  height: 100vh;
  z-index: 2;
  background: rgba(0, 0, 0, 0.5);
  display: grid;
}

.popup {
  background: rgba(255, 255, 255, 1);
  min-width: 800px;
  width: 40vw;
  border-radius: 10px;
  display: grid;
  position: relative;
  z-index: 3;
  align-self: center;
  justify-self: center;
  max-height: 85vh;
}

.popup-close {
  font-size: 28px;
  font-weight: normal;
  width: min-content;
  background: none;
  border: none;
  cursor: pointer;
  margin: 2%;
  text-decoration: none;
  color: rgba(0, 0, 0, 1);
  justify-self: end;
}

.popup-close:visited {
  text-decoration: none;
}

.popup-content {
  display: grid;
  padding: 1vh 2vw 3vh 2vw;
  min-width: 30vw;
  max-height: 50vh;
  overflow-y: auto;
  scrollbar-width: none;
}

.inside-popup-content-text {
  font-family: "Roboto Regular";
  width: 100%;
  height: 100%;
  text-align: justify;
}

.inside-popup-content {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 1vh;
  margin-bottom: 1vh;
  font-family: "Roboto Regular";
}

.inside-popup-container {
  margin: 3%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.inside-popup-title {
  font-family: "Roboto Bold";
  font-size: 1em;
  text-transform: capitalize;
  user-select: none;
}

.popup-button-primary {
  font-size: 0.8em;
  font-family: "Roboto Regular";
  background: cornflowerblue;
  padding: 1em 2em;
  cursor: pointer;
  overflow: hidden;
  border-radius: 10px;
  user-select: none;
  text-decoration: none;
}

.popup-button-secondary {
  font-size: 0.8em;
  font-family: "Roboto Regular";
  cursor: pointer;
  padding: 1em;
  user-select: none;
  text-decoration: none;
  color: black;
  margin: 0 5px;
}

.popup-button-primary:hover {
  background-color: lightskyblue;
}

.popup-button-secondary:hover {
  transform: scale(105%);
}

.popup-label {
  font-family: "Roboto Regular";
  user-select: none;
}

.popup-input {
  padding: 3px 6px;
  margin-left: 7px;
  border-radius: 10px;
}

.popup-select {
  display: inline;
  background-color: white;
  border-radius: 5px;
  border-color: rgba(0, 0, 0, 0.5);
  border-width: 2px;
  z-index: 100;
  margin-left: 10px;
  margin-right: 10px;
  font-family: "Roboto Medium";
  text-align-last: center;
}

.filename-by-type {
  list-style-type: none;
}

.type {
  font-weight: bold;
}

.popup-hr {
  color: black;
  height: 0.5px;
}

@media screen and (max-width: 800px) {
  .popup {
    min-width: unset;
    width: 100vw;
  }
}

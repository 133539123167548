.filter-bar {
  display: flex;
  flex-direction: column;
  gap: 1vh;
}

.select-area-anomaly {
  display: flex;
  justify-self: center;
  gap: 1vw;
}

.select-area-index {
  display: grid;
  justify-self: center;
  gap: 1vw;
}

.filter-area {
  display: flex;
  justify-content: center;
  column-gap: 1vw;
}

.filter-button {
  display: inline;
  height: 50px;
  width: 50px;
  background-color: white;
  border-radius: 10px;
  border-color: rgba(0, 0, 0, 0.5);
  border-width: 2px;
  position: relative;
  z-index: 1;
  fill: black;
}

.filter-button.active {
  background-color: cornflowerblue;
}

.filter-button.active:hover {
  background-color: lightskyblue;
}

.filter-button:hover {
  background-color: cornflowerblue;
  background-size: contain;
}

.filter-button:disabled {
  fill: darkgrey;
}

.filter-select {
  display: inline;
  height: 50px;
  width: 50px;
  background-color: white;
  border-radius: 10px;
  border-color: rgba(0, 0, 0, 0.5);
  border-width: 2px;
  position: relative;
  z-index: 1;
  font-family: "Roboto Medium";
  text-align-last: center;
}

.filter-select:hover {
  background-color: cornflowerblue;
  background-size: contain;
}

.filter-select option {
  background-color: white;
}

.filter-select-long {
  display: inline;
  height: 50px;
  width: 90px;
  background-color: white;
  border-radius: 10px;
  border-color: rgba(0, 0, 0, 0.5);
  border-width: 2px;
  position: relative;
  z-index: 1;
  font-family: "Roboto Medium";
  text-align-last: center;
}

.filter-select-long option {
  margin: 40px;
  background-color: white;
}

.filter-select-long:hover {
  background-color: cornflowerblue;
  background-size: contain;
}

.svg {
  width: 80%;
  height: 80%;
}

.filter-option {
  font-family: "Roboto Regular";
}

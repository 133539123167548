.legend-img {
  background: rgba(255, 255, 255, 1);
  position: relative;
  z-index: 1;
  height: 20vh;
  padding: 1vh 0.5vw;
  border-radius: 2vh;
  pointer-events: none;
  user-select: none;
  min-height: 200px;
}

.admin-user-upload-container {
  display: flex;
  flex-direction: column;
  gap: 2vh;
  justify-self: center;
  align-self: center;
}

.admin-user-upload-version-container {
  display: flex;
  flex-direction: row;
  align-self: center;
  gap: 1vw;
}

.admin-user-upload-file-container {
  display: flex;
  align-items: center;
}

.admin-user-upload-file-value {
  margin-left: 10px;
  text-align: right;
  height: 100%;
  width: 100%;
}

.admin-user-upload-version-file-uploader {
  display: flex;
  flex-direction: column;
  align-self: center;
  gap: 1vh;
}

.menu-container {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 10px;
}

.menu-button {
  position: relative;
  z-index: 1;
  height: 50px;
  width: 50px;
  background-color: white;
  border-radius: 10px;
  border-color: rgba(0, 0, 0, 0.5);
  border-width: 2px;
}

.menu-button:hover {
  background-color: cornflowerblue;
  background-size: contain;
}

.menu-button.active {
  background-color: cornflowerblue;
}

.menu-button.active:hover {
  background-color: lightskyblue;
}

select.menu-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: "";
}

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}

@font-face {
  font-family: "Roboto Black";
  src: local("Roboto Black"),
    url("../src/font/Roboto-Black.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto Black Italic";
  src: local("Roboto Black Italic"),
    url("../src/font/Roboto-BlackItalic.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto Bold";
  src: local("Roboto Bold"),
    url("../src/font/Roboto-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto Bold Italic";
  src: local("Roboto Bold Italic"),
    url("../src/font/Roboto-BoldItalic.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto Italic";
  src: local("Roboto Italic"),
    url("../src/font/Roboto-Italic.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto Light";
  src: local("Roboto Light"),
    url("../src/font/Roboto-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto Light Italic";
  src: local("Roboto Light Italic"),
    url("../src/font/Roboto-LightItalic.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto Medium";
  src: local("Roboto Medium"),
    url("../src/font/Roboto-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto Medium Italic";
  src: local("Roboto Medium Italic"),
    url("../src/font/Roboto-MediumItalic.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto Regular";
  src: local("Roboto Regular"),
    url("../src/font/Roboto-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto Thin";
  src: local("Roboto Thin"),
    url("../src/font/Roboto-Thin.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto Thin Italic";
  src: local("Roboto Thin Italic"),
    url("../src/font/Roboto-ThinItalic.ttf") format("truetype");
}

.panel {
  background: white;
  border-radius: 10px;
}

.panel-close {
  font-size: 20px;
  font-weight: normal;
  width: min-content;
  background: none;
  border: none;
  cursor: pointer;
  margin: 2%;
  text-decoration: none;
  color: rgba(0, 0, 0, 1);
  justify-self: end;
}

.title {
  font-family: "Roboto Bold";
  font-size: 1em;
  text-transform: capitalize;
  user-select: none;
  align-self: center;
}

.label {
  font-family: "Roboto Regular";
  user-select: none;
}

.select {
  background-color: white;
  border-radius: 10px;
  border-color: rgba(0, 0, 0, 0.5);
  border-width: 2px;
  font-family: "Roboto Regular";
}

.input {
  border-radius: 10px;
}

.link {
  font-size: 0.8em;
  font-family: "Roboto Regular";
  cursor: pointer;
  user-select: none;
  text-decoration: none;
  color: black;
}

.link:hover {
  transform: scale(105%);
}

.p {
  font-family: "Roboto Regular";
  text-align: justify;
}

.a {
  cursor: pointer;
  font-family: "Roboto Regular";
  color: cornflowerblue;
}

.a:visited {
  color: cornflowerblue;
}

.a-disabled {
  font-family: "Roboto Regular";
  color: gray;
}

.img {
  height: 10vmin;
  width: 10vmin;
}

.message-success {
  color: green;
  font-family: "Roboto Regular";
}

.message-error {
  color: red;
  font-family: "Roboto Regular";
}

.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner-container {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

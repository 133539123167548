.login-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 2fr 1fr 1fr 2fr 2fr 2fr;
  justify-self: center;
  align-self: center;
  gap: 1vh;
  width: min-content;
}

.login-alone-item {
  grid-column: span 2;
  justify-self: center;
  align-self: center;
}

.login-link-container {
  display: flex;
  flex-direction: row;
  gap: 1vw;
}

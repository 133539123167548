.loader-button {
  display: flex;
  border: 9px solid #f3f3f3; /* Light grey */
  border-top: 10px solid darkgrey; /* Blue */
  border-radius: 50%;
  width: 20px;
  height: 20px;
  align-items: center;
  justify-content: center;
  animation: spin 2s linear infinite;
}

.menu {
  position: absolute;
  top: 1vh;
  left: 1vw;
  width: 98vw;
  height: 98vh;
  display: grid;
  grid-template-columns: repeat(9, 1fr);
  grid-template-areas:
    "l . . mt mt mt . . mb"
    "l . . mt mt mt . . mb"
    ". . . . . . . . ."
    ". . . . . . . . ."
    ". . fb fb fb fb fb dp dp";
}

.main-title-container {
  grid-area: mt;
  align-self: start;
  justify-self: center;
}

.menu-bar-container {
  grid-area: mb;
  align-self: start;
  justify-self: end;
}

.legend-container {
  grid-area: l;
  align-self: start;
  justify-self: start;
}

.filter-bar-container {
  grid-area: fb;
  align-self: end;
  justify-self: center;
}

.download-panel-container {
  grid-area: dp;
  align-self: end;
  justify-self: end;
}

@media screen and (max-width: 1150px) {
  .menu {
    grid-template-areas:
      "l . . mt mt mt . . mb"
      "l . . mt mt mt . . mb"
      "dp dp dp dp dp dp dp dp dp"
      "dp dp dp dp dp dp dp dp dp"
      ". . fb fb fb fb fb . .";
  }

  .download-panel-container {
    align-self: end;
    justify-self: center;
  }
}

@media screen and (max-width: 1150px) and (max-height: 700px) {
  .menu {
    grid-template-areas:
      "l . . mt mt mt . . mb"
      "l . . mt mt mt . . mb"
      "l dp dp dp dp dp dp dp mb"
      "l dp dp dp dp dp dp dp mb"
      "l . fb fb fb fb fb . mb";
  }

  .download-panel-container {
    align-self: end;
    justify-self: center;
  }
}

.main-title {
  position: relative;
  font-size: 10vmin;
  font-family: "Roboto Black", serif;
  -webkit-text-stroke: 1px black;
  color: rgba(256, 256, 256, 1);
  cursor: pointer;
  user-select: none;
  text-decoration: none;
  z-index: 1;
}

.main-title:hover {
  color: rgba(256, 256, 256, 0.9);
}

.download-panel {
  display: grid;
  position: relative;
  z-index: 1;
  margin-bottom: 1%;
  margin-right: 1%;
}

.download-panel-content {
  display: flex;
  padding: 1vh 2vw;
  min-width: 30vw;
  max-height: 40vh;
  overflow-y: auto;
  scrollbar-width: none;
}

.inside-download-panel-container {
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
}

.inside-download-panel-button {
  align-self: center;
  font-family: "Roboto Regular", serif;
  padding: 2vh;
  grid-row: span 2;
}

.inside-download-panel-loading {
  justify-self: center;
  align-self: center;
  font-family: "Roboto Regular", serif;
}

.inside-download-panel-form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  font-family: "Roboto Regular", serif;
  max-width: 200px;
  align-items: center;
  grid-gap: 1vh;
}

.inside-download-panel-form-select {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  font-family: "Roboto Regular", serif;
  max-width: 200px;
  align-items: center;
  grid-gap: 1vh;
}

.inside-download-panel-header {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.inside-download-panel-form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  font-family: "Roboto Regular", serif;
  max-width: 200px;
  justify-self: center;
  grid-gap: 1vh;
}

.panel-select {
  display: inline;
  background-color: white;
  border-radius: 5px;
  border-color: rgba(0, 0, 0, 0.5);
  border-width: 2px;
  position: relative;
  z-index: 1;
  margin-left: 10px;
  margin-right: 10px;
  font-family: "Roboto Medium";
  text-align-last: center;
  width: fit-content;
  justify-self: center;
}

.inside-download-panel-description {
  display: grid;
  gap: 0;
  grid-template-rows: 1fr;
  justify-self: center;
  align-self: center;
  font-family: "Roboto Regular";
}

.forgotten-password-change-password-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 2fr 1fr 1fr 2fr 2fr;
  justify-self: center;
  align-self: center;
  gap: 1vh;
  width: min-content;
}

.forgotten-password-send-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 2fr 1fr 2fr 2fr;
  justify-self: center;
  align-self: center;
  gap: 1vh;
  width: min-content;
}

.forgotten-password-alone-item {
  grid-column: span 2;
  justify-self: center;
  align-self: center;
}

.information-container {
  display: flex;
  justify-self: center;
  align-self: center;
  gap: 2vh;
  flex-direction: column;
}

.information-img-item {
  display: flex;
  justify-self: center;
  align-self: center;
  flex-direction: row;
}
